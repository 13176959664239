import { subMonths, subYears } from "date-fns";
import { defineStore } from "pinia";

import {
  getUserSummary,
  getUserDemography,
  getCumulativeUsers,
  getCumulativeMeetings,
  getActiveUsers,
  getNewUsers,
  getUsersWithFirstMeetings,
  getMeetings,
  getUsersWithMeeting,
  getAverageMeetingDurations,
  getCheckupResults,
  getContentConsumption,
  getContentConsumptionsByType,
  getContentPopularity,
  getMeetingCountsPerUser,
} from "~/helpers/api/status";
import { generatePDF } from "~/helpers/pdf/pdfgenerator";
import type { Sponsor } from "~/model/sponsor";
import {
  UserSummary,
  UserDemography,
  Aggregation,
  CheckupResult,
  Popularity,
} from "~/model/status";
import type { InsightsProfile } from "~/model/user";
import { VerticalEnum } from "~/model/vertical";

export type FilterRange = "last3Months" | "last6Months" | "lastYear";
export const useStatusStore = defineStore("status", {
  state: () => ({
    filter: {
      sponsorIds: null as string[] | null,
      range: "last3Months" as FilterRange,
      vertical: VerticalEnum.All as VerticalEnum,
    },
    isLoading: false,
    userSummary: null as UserSummary | null,
    userDemography: null as UserDemography | null,
    cumulativeUsers: null as Aggregation[] | null,
    cumulativeMeetings: null as Aggregation[] | null,
    activeUsers: null as Aggregation[] | null,
    newUsers: null as Aggregation[] | null,
    usersWithFirstMeetings: null as Aggregation[] | null,
    meetings: null as Aggregation[] | null,
    usersWithMeeting: null as Aggregation[] | null,
    meetingCountsPerUser: null as { [key: string]: number } | null,
    averageMeetingDurations: null as Aggregation[] | null,
    checkupResults: null as CheckupResult | null,
    contentConsumption: null as Aggregation[] | null,
    contentConsumptionsByType: null as { [key: string]: number } | null,
    contentPopularity: null as Popularity[] | null,
    isInitialized: false,
    isPdfExporting: false,
    isExportApiLoading: false,
    verticalExport: {
      meetings: {} as { [key: string]: Aggregation[] | null },
      usersWithMeeting: {} as { [key: string]: Aggregation[] | null },
      meetingCountsPerUser: {} as {
        [key: string]: { [key: string]: number } | null;
      },
    },
  }),
  getters: {
    getIsInitialized: (state) => {
      return state.isInitialized;
    },
  },
  actions: {
    initializeFilter(profile: InsightsProfile) {
      const localStorageFilter = LocalStorageUtil.getItem("statusFilter");
      if (
        localStorageFilter &&
        localStorageFilter.sponsorIds &&
        localStorageFilter.sponsorIds?.every((s) =>
          profile.sponsors.some((ps) => ps.id === s)
        )
      ) {
        this.filter = localStorageFilter;
      } else if (profile && this.filter.sponsorIds === null) {
        this.filter.sponsorIds = [profile.sponsors[0].id];
      }

      this.persistFilter();
    },
    updateFilterSponsorIds(ids: string[]) {
      ids.sort();
      this.filter.sponsorIds = ids;
      this.persistFilter();
    },
    updateFilterRange(range: FilterRange) {
      this.filter.range = range;
      this.persistFilter();
    },
    updateFilterVertical(verticalEnum: VerticalEnum) {
      this.filter.vertical = verticalEnum;
      this.persistFilter();
    },
    persistFilter() {
      LocalStorageUtil.setItem("statusFilter", this.filter);
    },
    async exportToPdf(sponsors: Sponsor[]) {
      const sponsorLogoUrl = sponsors[0].logoUrl;
      const sponsorName = sponsors[0].name;
      this.isPdfExporting = true;
      try {
        this.isExportApiLoading = true;
        const promises = verticalOptions.map((vertical) => {
          return Promise.all([
            this.loadMeetings(
              this.filter.sponsorIds!,
              this.filter.range,
              vertical,
              true
            ),
            this.loadUsersWithMeeting(
              this.filter.sponsorIds!,
              this.filter.range,
              vertical,
              true
            ),
            this.loadMeetingCountsPerUser(
              this.filter.sponsorIds!,
              this.filter.range,
              vertical,
              true
            ),
          ]);
        });

        await Promise.all(promises);

        this.isExportApiLoading = false;
        await generatePDF(
          convertRangeToStartDate(this.filter.range),
          sponsorLogoUrl,
          sponsorName,
          sponsors.map((sponsor) => sponsor.name),
          sponsors.length > 1
        );
      } catch (error) {
        console.error("Error exporting to PDF:", error);
      } finally {
        this.isPdfExporting = false;
        this.isExportApiLoading = false;
      }
    },
    async loadUserSummary(sponsorIds: string[]) {
      await getUserSummary(sponsorIds).then((response) => {
        this.userSummary = response;
      });
    },
    async loadUserDemography(sponsorIds: string[]) {
      await getUserDemography(sponsorIds).then((response) => {
        this.userDemography = response;
      });
    },
    async loadCumulativeUsers(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getCumulativeUsers(sponsorIds, startDate, "").then((response) => {
        this.cumulativeUsers = response;
      });
    },
    async loadCumulativeMeetings(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getCumulativeMeetings(sponsorIds, startDate, "").then(
        (response) => {
          this.cumulativeMeetings = response;
        }
      );
    },
    async loadActiveUsers(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getActiveUsers(sponsorIds, startDate).then((response) => {
        this.activeUsers = response;
      });
    },
    async loadNewUsers(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getNewUsers(sponsorIds, startDate).then((response) => {
        this.newUsers = response;
      });
    },
    async loadUsersWithFirstMeetings(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getUsersWithFirstMeetings(sponsorIds, startDate).then(
        (response) => {
          this.usersWithFirstMeetings = response;
        }
      );
    },
    async loadMeetings(
      sponsorIds: string[],
      range: FilterRange,
      vertical: string,
      isExport: boolean = false
    ) {
      const startDate = convertRangeToStartDate(range);
      const response = await getMeetings(sponsorIds, startDate, vertical);

      if (isExport) {
        this.verticalExport.meetings[vertical] = response;
      } else {
        this.meetings = response;
      }

      return response;
    },
    async loadUsersWithMeeting(
      sponsorIds: string[],
      range: FilterRange,
      vertical: string,
      isExport: boolean = false
    ) {
      const startDate = convertRangeToStartDate(range);
      const response = await getUsersWithMeeting(
        sponsorIds,
        startDate,
        vertical
      );

      if (isExport) {
        this.verticalExport.usersWithMeeting[vertical] = response;
      } else {
        this.usersWithMeeting = response;
      }

      return response;
    },
    async loadMeetingCountsPerUser(
      sponsorIds: string[],
      range: FilterRange,
      vertical: string,
      isExport: boolean = false
    ) {
      const startDate = convertRangeToStartDate(range);
      const response = await getMeetingCountsPerUser(
        sponsorIds,
        startDate,
        vertical
      );

      if (isExport) {
        this.verticalExport.meetingCountsPerUser[vertical] = response;
      } else {
        this.meetingCountsPerUser = response;
      }

      return response;
    },
    async loadAverageMeetingDurations(
      sponsorIds: string[],
      range: FilterRange,
      vertical?: string
    ) {
      const startDate = convertRangeToStartDate(range);
      await getAverageMeetingDurations(sponsorIds, startDate, vertical).then(
        (response) => {
          this.averageMeetingDurations = response;
        }
      );
    },
    async loadCheckupResults(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getCheckupResults(sponsorIds, startDate).then((response) => {
        this.checkupResults = response;
      });
    },
    async loadContentConsumption(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getContentConsumption(sponsorIds, startDate).then((response) => {
        this.contentConsumption = response;
      });
    },
    async loadContentConsumptionsByType(
      sponsorIds: string[],
      range: FilterRange
    ) {
      const startDate = convertRangeToStartDate(range);
      await getContentConsumptionsByType(sponsorIds, startDate).then(
        (response) => {
          this.contentConsumptionsByType = response;
        }
      );
    },
    async loadContentPopularity(sponsorIds: string[], range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getContentPopularity(sponsorIds, startDate).then((response) => {
        this.contentPopularity = response;
      });
    },
  },
});

const convertRangeToStartDate = (range: FilterRange) => {
  const date = new Date();
  switch (range) {
    case "last3Months":
      return subMonths(date, 3);
    case "last6Months":
      return subMonths(date, 6);
    case "lastYear":
      return subYears(date, 1);
    default:
      return date;
  }
};

export const verticalOptions: VerticalEnum[] = [
  VerticalEnum.All,
  VerticalEnum.MentalHealth,
  VerticalEnum.Nutrition,
  VerticalEnum.PhysicalHealth,
  VerticalEnum.Other,
];
