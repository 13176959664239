<template>
  <ChartBox
    id="contentTypesContainer"
    title="İçerik tipleri"
    subtitle="Tüketilen içerik türlerinin dağılımı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isEmpty"
      :isEmpty="isEmpty"
      :loading="loading"
    />
    <ChartDonut
      v-else
      chartId="contentByType"
      :data="
        generateDonutChartData(
          contentConsumptionsByType,
          'İçerik türü',
          displayNameDictionary
        )
      "
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const contentConsumptionsByType = computed(
  () => statusStore.contentConsumptionsByType
);
const isEmpty = computed(
  () =>
    !contentConsumptionsByType.value ||
    Object.keys(contentConsumptionsByType.value).length === 0
);

const [loading, runWithLoading] = useLoading();

const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadContentConsumptionsByType(
      filter.value.sponsorIds,
      filter.value.range
    )
  );
};

const displayNameDictionary: {
  [key: string]: string;
} = {
  Other: "Diğer",
  Video: "Video",
  Audio: "Sesli içerik",
  Article: "Makale",
  Story: "Öneri",
  DailyTip: "Günlük öneri",
  CBTExercise: "BDT egzersizi",
};
onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorIds, filter.value.range],
  async ([newSponsorIds, newRange], [oldSponsorIds, oldRange]) => {
    if (newSponsorIds !== oldSponsorIds || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
