<template>
  <section
    id="header"
    class="grid grid-cols-[auto,1fr,1fr,1fr] gap-2 items-center bg-neutral-60 rounded-xl p-4"
    v-if="filterSponsor"
  >
    <div
      class="h-16 w-16 bg-surface-10 rounded-lg flex items-center justify-center"
    >
      <Img
        id="sponsorLogo"
        :src="filterSponsor.logoUrl"
        :alt="`${filterSponsor.name} Logo`"
        class="h-full w-full object-contain rounded-lg"
      />
    </div>
    <div class="grid gap-2">
      <span class="l3 text-surface-10 text-left">İsim Soyisim</span>
      <span class="b1 text-surface-10 text-left">
        {{ insightsProfile.firstName }} {{ insightsProfile.lastName }}
      </span>
    </div>
    <div class="grid gap-2">
      <span class="l3 text-surface-10 text-left">E-posta adresi</span>
      <span class="b1 text-surface-10 text-left">
        {{ insightsProfile.email }}
      </span>
    </div>
    <div class="grid gap-2">
      <span class="l3 text-surface-10 text-left">Sponsorluk ismi</span>
      <div class="flex items-center gap-2">
        <span class="b1 text-surface-10 text-left select-none">
          {{ filterSponsor.name }}
        </span>
        <Tooltip
          v-if="filterSponsors.length > 1"
          :tooltipContent="
            filterSponsors.map((sponsor) => sponsor.name).join(', ')
          "
        >
          <span class="b1 text-surface-10 text-left select-none"
            >, +{{ filterSponsors.length - 1 }}
          </span>
        </Tooltip>

        <ButtonSecondarySmall
          v-if="insightsProfile.sponsors.length > 1"
          label="Değiştirin"
          :inversed="true"
          @click="changeSponsorSheetOpen = true"
        />
        <ReportsSponsorSlideSheet
          :open="changeSponsorSheetOpen"
          :onClose="() => (changeSponsorSheetOpen = false)"
        />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import { useUserStore } from "~/store/user";

const userStore = useUserStore();
const statusStore = useStatusStore();

const insightsProfile = computed(() => userStore.insightsProfile);
const filter = computed(() => statusStore.filter);
const filterSponsor = computed(() =>
  filter.value.sponsorIds && filter.value.sponsorIds.length > 0
    ? userStore.getSponsorById(filter.value.sponsorIds[0])
    : null
);

const filterSponsors = computed(() =>
  filter.value.sponsorIds && filter.value.sponsorIds.length > 0
    ? userStore.getSponsorsByIds(filter.value.sponsorIds)
    : null
);

const changeSponsorSheetOpen = ref(false);
</script>
