class ApiUtils {
  static toQueryString(params: Record<string, any>): string | null {
    const paramPrefix = Object.entries(params).every(
      (_) => _ == null || _ == undefined
    )
      ? ""
      : "?";
    const paramString =
      paramPrefix +
      Object.entries(params)
        .flatMap(([key, value]) => {
          if (value === null || value === undefined) {
            return null;
          }

          if (Array.isArray(value)) {
            return value.map(
              (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            );
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .filter(Boolean)
        .join("&");

    return paramString.length > 0 ? paramString : null;
  }

  static extractQueryString(event: any): string {
    return (
      ApiUtils.toQueryString(event.context.params) ??
      event.node.req.url.split("?")[1]
    );
  }

  static stringifyWithMap(obj: any): string {
    const replacer = (key: string, value: any) => {
      if (value instanceof Map) {
        return {
          dataType: "Map",
          value: Array.from(value.entries()), // Convert Map to array of key-value pairs
        };
      }
      return value;
    };

    return JSON.stringify(obj, replacer);
  }

  static parseWithMap(json: string): any {
    const reviver = (key: string, value: any) => {
      if (
        typeof value === "object" &&
        value !== null &&
        value.dataType === "Map"
      ) {
        return new Map(value.value); // Convert array of key-value pairs to Map
      }
      return value;
    };

    return JSON.parse(json, reviver);
  }
}

export default ApiUtils;
