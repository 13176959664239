import type {
  Aggregation,
  CheckupResult,
  Popularity,
  UserDemography,
  UserSummary,
} from "~/model/status";
import { axiosInstance } from "~/plugins/axios";
import ApiUtils from "~/utils/api";

const apiPathPrefix = "/api/status";
export const getUserSummary = async (
  sponsorIds: string[]
): Promise<UserSummary> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
  });

  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetUserSummary${queryString}`
  );

  return data as UserSummary;
};

export const getUserDemography = async (
  sponsorIds: string[]
): Promise<UserDemography> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetUserDemography${queryString}`
  );

  return data as UserDemography;
};

export const getCumulativeUsers = async (
  sponsorIds: string[],
  startDate: Date,
  vertical: string
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
    vertical,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetCumulativeUsers${queryString}`
  );

  return data as Aggregation[];
};

export const getCumulativeMeetings = async (
  sponsorIds: string[],
  startDate: Date,
  vertical: string
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
    vertical: vertical,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetCumulativeMeetings${queryString}`
  );

  return data as Aggregation[];
};

export const getActiveUsers = async (
  sponsorIds: string[],
  startDate: Date
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetActiveUsers${queryString}`
  );

  return data as Aggregation[];
};

export const getNewUsers = async (
  sponsorIds: string[],
  startDate: Date
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetNewUsers${queryString}`
  );

  return data as Aggregation[];
};

export const getUsersWithFirstMeetings = async (
  sponsorIds: string[],
  startDate: Date
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetUsersWithFirstMeetings${queryString}`
  );

  return data as Aggregation[];
};

export const getMeetings = async (
  sponsorIds: string[],
  startDate: Date,
  vertical: string
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
    vertical,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetMeetings${queryString}`
  );

  return data as Aggregation[];
};

export const getUsersWithMeeting = async (
  sponsorIds: string[],
  startDate: Date,
  vertical: string
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
    vertical,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetUsersWithMeeting${queryString}`
  );

  return data as Aggregation[];
};

export const getMeetingCountsPerUser = async (
  sponsorIds: string[],
  startDate: Date,
  vertical: string
): Promise<{ [key: string]: number }> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
    vertical: vertical,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetMeetingCountsPerUser${queryString}`
  );
  return data as { [key: string]: number };
};

export const getAverageMeetingDurations = async (
  sponsorIds: string[],
  startDate: Date,
  vertical: string
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
    vertical: vertical,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetAverageMeetingDurations${queryString}`
  );

  return data as Aggregation[];
};

export const getCheckupResults = async (
  sponsorIds: string[],
  startDate: Date
): Promise<CheckupResult> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetCheckupResults${queryString}`
  );

  return data as CheckupResult;
};

export const getContentConsumption = async (
  sponsorIds: string[],
  startDate: Date
): Promise<Aggregation[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetContentConsumption${queryString}`
  );

  return data as Aggregation[];
};

export const getContentConsumptionsByType = async (
  sponsorIds: string[],
  startDate: Date
): Promise<{ [key: string]: number }> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetContentConsumptionsByType${queryString}`
  );

  return data as { [key: string]: number };
};

export const getContentPopularity = async (
  sponsorIds: string[],
  startDate: Date
): Promise<Popularity[]> => {
  const queryString = ApiUtils.toQueryString({
    sponsorIds: sponsorIds,
    startDate: startDate.toISOString(),
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetContentPopularity${queryString}`
  );

  return data as Popularity[];
};
