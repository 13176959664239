<template>
  <ChartBox
    id="activeUsersContainer"
    title="Aktif kullanıcılar"
    subtitle="Heltia’dan ay içerisinde en az 1 kere faydalanmış kullanıcı sayısı."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isActiveUsersEmpty"
      :isEmpty="isActiveUsersEmpty"
      :loading="loading"
    />
    <ChartColumn
      v-else
      chartId="newcomingUsers"
      :data="generateColumnChartData(activeUsers, 'Aktif kullanıcılar')"
      yAxisTitle="Kişi sayısı"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";

const statusStore = useStatusStore();
const filter = computed(() => statusStore.filter);
const activeUsers = computed(() => statusStore.activeUsers);
const isActiveUsersEmpty = computed(
  () => !activeUsers.value || activeUsers.value.length === 0
);

const [loading, runWithLoading] = useLoading();
const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadActiveUsers(filter.value.sponsorIds, filter.value.range)
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorIds, filter.value.range],
  async ([newSponsorIds, newRange], [oldSponsorIds, oldRange]) => {
    if (newSponsorIds !== oldSponsorIds || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
