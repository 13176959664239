<template>
  <ModalSlideSheet
    title="Sponsorluğu değiştirin"
    :open="open"
    :onClose="onClose"
    :onBack="onClose"
  >
    <template #content>
      <AppBaseColumn gap="small" class="w-full">
        <span class="l1 text-start">Sponsorluk seçin</span>
        <OptionItemMultiple
          v-for="sponsor in availableSponsors"
          :key="sponsor.id"
          :option="`${sponsor.name} - ${sponsor.identifier}`"
          :isSelected="selectedIds.includes(sponsor.id)"
          :onSelect="() => onSelect(sponsor.id)"
        />
      </AppBaseColumn>
    </template>
    <template #footer-secondary-button>
      <ButtonSecondaryMedium label="Vazgeçin" @click="() => onClose()" />
    </template>
    <template #footer-primary-button>
      <ButtonPrimaryMedium
        label="Güncelleyin"
        :disabled="
          arraysEqual(selectedIds, initialIds) || selectedIds.length === 0
        "
        @click="onConfirm"
      />
    </template>
  </ModalSlideSheet>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import { useUserStore } from "~/store/user";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const userStore = useUserStore();
const statusStore = useStatusStore();

const availableSponsors = computed(() => userStore.insightsProfile.sponsors);
const initialIds = computed(() => statusStore.filter.sponsorIds);
const selectedIds = ref(initialIds.value);
const onSelect = (id: string) => {
  if (selectedIds.value.includes(id)) {
    selectedIds.value = selectedIds.value.filter(
      (selectedId) => selectedId !== id
    );
  } else {
    selectedIds.value = [...selectedIds.value];
    selectedIds.value.push(id);
  }
};

const arraysEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false;
  a.sort();
  b.sort();
  return a.every((val, index) => val === b[index]);
};

const onConfirm = () => {
  statusStore.updateFilterSponsorIds(selectedIds.value);
  props.onClose();
};
</script>
