<template>
  <div
    class="bg-surface-10 border border-solid px-3 py-4 rounded-xl cursor-pointer outline-none select-none flex items-center gap-3"
    :class="{
      'border-primaryV2': isSelected,
      'border-surface-60': !isSelected,
    }"
    @click="onCheckboxClick"
  >
    <span
      class="m-0 text-[18px]"
      :class="
        isSelected ? 'icon-checkboxChecked text-primaryV2' : 'icon-checkbox'
      "
    />
    <label class="b2 cursor-pointer" :class="{ 'text-primaryV2': isSelected }">
      {{ option }}
    </label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  option: String,
  isSelected: Boolean,
  onSelect: Function,
});

const { onSelect, option } = props;

const onCheckboxClick = () => {
  onSelect([option]);
};
</script>
