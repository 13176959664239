<template>
  <span
    v-tooltip="{
      content: props.tooltipContent,
      placement: props.placement,
      theme: 'info-tooltip',
    }"
  >
    <slot></slot>
  </span>
</template>

<script setup lang="ts">
const props = defineProps({
  tooltipContent: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: "top",
  },
});
</script>
