<template>
  <ChartBox
    id="averageMeetingDurationsContainer"
    title="Ortalama seans süreleri"
    subtitle="Aktif kullanıcı başına aylık ortalama seans süresi."
    :bottomVisible="false"
  >
    <ChartLoading
      v-if="loading || isEmpty"
      :isEmpty="isEmpty"
      :loading="loading"
    />
    <ChartLine
      v-else
      chartId="averageMeetingDurations"
      :data="generateColumnChartData(averageMeetingDurations, sponsor.name)"
      yAxisTitle="Dakika"
      :staticLineValue="averageMeetingDuration"
      staticLineCategory="50+ şirketin ortalaması"
    />
  </ChartBox>
</template>
<script setup lang="ts">
import { useStatusStore } from "~/store/status";
import { useUserStore } from "~/store/user";

const config = useRuntimeConfig();
const averageMeetingDuration = parseInt(config.public.averageMeetingDuration);

const statusStore = useStatusStore();
const userStore = useUserStore();

const filter = computed(() => statusStore.filter);
const averageMeetingDurations = computed(
  () => statusStore.averageMeetingDurations
);

const isEmpty = computed(
  () =>
    !averageMeetingDurations.value || averageMeetingDurations.value.length === 0
);

const sponsor = computed(() =>
  userStore.getSponsorById(filter.value.sponsorIds[0])
);

const [loading, runWithLoading] = useLoading();
const fetchData = async () => {
  await runWithLoading(() =>
    statusStore.loadAverageMeetingDurations(
      filter.value.sponsorIds,
      filter.value.range
    )
  );
};

onBeforeMount(fetchData);

watch(
  () => [filter.value.sponsorIds, filter.value.range],
  async ([newSponsorIds, newRange], [oldSponsorIds, oldRange]) => {
    if (newSponsorIds !== oldSponsorIds || newRange !== oldRange) {
      await fetchData();
    }
  }
);
</script>
